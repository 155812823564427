@media only screen and (min-width: 1200px) {
  section.about_us > .container{
    min-height: 50vh;
    display: flex;
    justify-content: center;
  }
  section.about_us > .container > .row{
    width: 100%;
    margin-top: auto;
    margin-bottom: auto;
  }
}


#hero {
  height: 75vh;
}

#hero h1 {
  font-size: 18px;
  color: #999;
}

#hero h2 {
  font-size: 5rem;
  max-width: 15ch;
}

#hero img {
  margin-top: 8px;
  max-height: 200px;
}

#introduce {
  color: white;
  border-left: 3px solid var(--yellow);
  padding-left: 16px;
  line-height: 1.5;
}

.about_us p {
  line-height: 2;
}

.project_img {
  width: 100%;
  max-width: 178px;
  height: auto;
  border-radius: 100%;
}

.project_logo {
  width: 100%;
  max-width: 165px;
  height: auto;
}

.scale {
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-property: transform;
  transition-property: transform;
}

.scale:hover {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

.swiper-wrapper{
  display: flex;
  align-items: center;
}